import React, { Component } from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { sunburst } from 'react-syntax-highlighter/dist/styles/hljs'
import Gallery from '../common/Gallery'

export default class ImageGallery extends Component {
    render() {
        const { data } = this.props

        return (
            <div className="section">
                <h6 className="section-title">Image Gallery</h6>
                <div className="card">
                    <div className="example card-body">
                        <Gallery data={data} />
                    </div>
                    <SyntaxHighlighter className="code card-foot" language='html' style={sunburst}>
                    {`
    const data = {
        title: 'Image gallery',
        images: [{
            title: 'Image 1 title',
            alt: 'Image Gallery - Photo 1',
            src: 'http://placehold.it/1200x600'
        },{
            title: 'Image 2',
            alt: 'Image Gallery - Photo 2',
            src: 'http://placehold.it/1800x1800&text=Slide2'
        }]
    }

    <Gallery data={data} />
            `}
                    </SyntaxHighlighter>
                </div>
            </div>
        )
    }
}