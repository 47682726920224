import React, { Component } from 'react';

export default class Color extends Component {
  render() {
    return (
        <div className="section">
            <div className="panel">
                <div className="group">
                    <div className="primary main color-box">
                    $primary
                    </div>
                    <div className="primary-darker color-box">
                    $primary-darker
                    </div>
                    <div className="primary-dark color-box">
                    $primary-dark
                    </div>
                    <div className="primary-light color-box">
                    $primary-light
                    </div>
                    <div className="primary-lighter color-box">
                    $primary-lighter
                    </div>
                </div>
                
                <div className="group">
                    <div className="secondary main color-box">
                    $secondary
                    </div>
                    <div className="secondary-darker color-box">
                    $secondary-darker
                    </div>
                    <div className="secondary-dark color-box">
                    $secondary-dark
                    </div>
                    <div className="secondary-light color-box">
                    $secondary-light
                    </div>
                    <div className="secondary-lighter color-box">
                    $secondary-lighter
                    </div>
                </div>

                <div className="group">
                    <div className="black main color-box">
                    $black
                    </div>
                    <div className="off-black color-box">
                    $off-black
                    </div>
                    <div className="off-black-light color-box">
                    $off-black-light
                    </div>
                    <div className="off-black-lighter color-box">
                    $off-black-lighter
                    </div>
                    <div className="off-black-lightest color-box">
                    $off-black-lightest
                    </div>
                </div>

                <div className="group">
                    <div className="grey main color-box">
                    $grey
                    </div>
                    <div className="grey-darker color-box">
                    $grey-darker
                    </div>
                    <div className="grey-dark color-box">
                    $grey-dark
                    </div>
                    <div className="grey-light color-box">
                    $grey-light
                    </div>
                    <div className="grey-lighter color-box">
                    $grey-lighter
                    </div>
                </div>

                <div className="group">
                    <div className="white main color-box">
                    $white
                    </div>
                    <div className="off-white-darkest color-box">
                    $off-white-darkest
                    </div>
                    <div className="off-white-darker color-box">
                    $off-white-darker
                    </div>
                    <div className="off-white-dark color-box">
                    $off-white-dark
                    </div>
                    <div className="off-white color-box">
                    $off-white
                    </div>
                </div>
            </div>
        </div>
    )
  }
}