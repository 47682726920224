import React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { sunburst } from 'react-syntax-highlighter/dist/styles/hljs'

export default () => {
    return (
        <div className="section">
            <h6 className="section-title">TABLES</h6>
            <div className="card">
                <div className="example card-body">
                    <table className="table">
                    <thead>
                        <tr>
                        <th colSpan="4">Cardiovascular</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>
                                SVT <br />
                                Heart block <br />
                                Truncus arteriosus <br />
                                Coxsackie myocarditis</td>
                            <td>
                                Hypoplastic Left Heart Syndrome
                                Endocardial fibroelastosis
                                VSD/AV canal
                                Premature closure of foramen ovale	
                            </td>
                            <td>
                                Premature closure of PDA
                                Tumours (rhabdomyomas)
                                Arterial calcification
                                Cardiomyopathy (e.g. carnitine deficiency)
                            </td>
                            <td>
                                AV malformations
                                Any cause of heart failure
                            </td>
                        </tr>
                        <tr>
                            <td>Trisomy 21</td>
                            <td>Triploidy</td>
                            <td>45XO (Turner’s)</td>
                            <td>Many others reported</td>
                        </tr>
                    </tbody>

                    </table>
                </div>
                <SyntaxHighlighter className="code card-foot" language='html' style={sunburst}>
                {`
    <table className="table">
        <thead>
            <tr>
            <th colSpan="2">Table Heading 1</th>
            </tr>
        </thead>

        <tbody>
            <tr>
            <td>
                Table Content 1
            <td>
                Table Content 2	
            </td>
            </tr>
        </tbody>
    </table>
                `}
                </SyntaxHighlighter>
            </div>
        </div>
    )
}