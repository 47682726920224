import React, { Component } from 'react'
import Color from '../components/styleguide/Color'
import Font from '../components/styleguide/Font'
import Table from '../components/styleguide/Table'
import ImageGallery from '../components/styleguide/ImageGallery'
import SearchStyle from '../components/styleguide/SearchStyle'
import '../assets/styles/app.scss'

/* eslint-disable */

const data = {
  title: 'Image gallery',
  images: [{
    title: 'Image 1 title',
    alt: 'Image Gallery - Photo 1',
    src: 'http://placehold.it/1200x600'
  },{
    title: 'Image 2',
    alt: 'Image Gallery - Photo 2',
    src: 'http://placehold.it/1800x1800&text=Slide2'
  }]
}

export default class StyleGuide extends Component {
    render() {
        return (
            <div id="styleguide">
                <div className="main">
                    <div className="wrapper">
                        <aside className="side-menu">
                        <div className="section">
                            <h6>Basic</h6>
                            <a href="#">Colors</a>
                            <a href="#">Fonts</a>

                            <h6>Components</h6>
                            <a href="#">Tables</a>
                            <a href="#">Image Gallery</a>
                            <a href="#">Search</a>
                        </div>
                        </aside>

                        <div className="styleguide-content">
                            <Color />
                            
                            <Font />
                            
                            <Table />

                            <ImageGallery data={data} />

                            <SearchStyle />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}