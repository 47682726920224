import React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { sunburst } from 'react-syntax-highlighter/dist/styles/hljs'
import { SearchBar } from '../common'

export default () => (
    <div className="section">
        <h6 className="section-title">Search</h6>
        <div className="card">
            <div className="example card-body" style={{backgroundColor: '#EFEFEF', height: 500}}>
                <SearchBar id={'searchbar-style'} />
            </div>
            <SyntaxHighlighter className="code card-foot" language='html' style={sunburst}>
            {`
                Code here...
            `}
            </SyntaxHighlighter>
        </div>
    </div>
)