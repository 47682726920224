import React, { Component } from 'react'

export default class Font extends Component {
  render() {
    return (
      <div className="section">
        <div className="panel">
          <div className="fonts group">
            <h6 className="section-title">Heading 1</h6>
            <h1>Paediatric Allergy Clinical Network</h1>

            <h6 className="section-title">Heading 2</h6>
            <h2>Paediatric Allergy Clinical Network</h2>

            <h6 className="section-title">Heading 3</h6>
            <h3>Paediatric Allergy Clinical Network</h3>

            <h6 className="section-title">Heading 4</h6>
            <h4>Paediatric Allergy Clinical Network</h4>

            <h6 className="section-title">Heading 5</h6>
            <h5>Paediatric Allergy Clinical Network</h5>

            <h6 className="section-title">Heading 6</h6>
            <h6>Paediatric Allergy Clinical Network</h6>
          </div>
        </div>
      </div>
    )
  }
}